
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Filter Items
 */
.b-filter-items {
  @include respond-to(desktop, tablet) {
    .x-columns {
      float: left;
    }

    .child-link {
      text-align: left;
    }
  }

  @include respond-to(small-desktop, tablet) {
    .x-columns {
      width: 45%;
    }
  }

  @include respond-to(mobile) {
    .child-link {
      margin-top: $single-space;
      text-align: left;
    }

    .nav-link .ico-chevron-right {
      position: absolute;
      top: 50%;
      right: 0;
    }
  }

  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: $color-white;

  .x-columns {
    overflow: hidden;
  }

  .x-search-columns,
  .x-search-filter-columns,
  .x-parent-element {
    padding-left: $single-space;

    .nav-link {
      font-size: $base-font-size;
      font-weight: $content-font-weight-light;
      line-height: $content-line-height-xxsh;
      white-space: nowrap;
    }

    .x-current-selection,
    .filter-selected-menu {
      font-weight: $content-font-weight-normal;
      text-decoration: underline;
    }
  }

  .x-parent-element {
    padding-left: 0;

    .nav-link {
      font-weight: $content-font-weight-normal;
    }
  }

  .nav-link {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: baseline;
    width: 100%;
    height: 44px;
    font-size: $base-font-size;
    line-height: $content-line-height-xxl;
    color: $color-black;

    .ico-chevron-right {
      position: relative;
      top: auto;
      right: auto;
      font-size: $content-font-size-xxxs;
    }

    .ico-chevron-left {
      font-size: $content-font-size-xxxs;
      line-height: $content-line-height-xs;
    }
  }

  .ico {
    padding-right: $single-space;
  }

  .icon-text {
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;
  }
}

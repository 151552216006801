
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

.b-modal {
  @include respond-to(desktop) {
    .modal-content {
      overflow-x: hidden;
      width: $modal-regular-width;
    }

    .modal-extra-wide {
      width: $modal-wide-width;

      .button-group-wrapper {
        width: $modal-wide-width;
        text-align: center;
      }
    }

    .modal-extra-extra-wide {
      width: $modal-extra-extra-wide-width;

      .button-group-wrapper {
        text-align: center;
      }
    }

    &.x-active {
      right: -540px;
      width: calc(100% + 540px);

      .modal-content {
        display: none;
      }
    }

    &.x-active-slide-in {
      transform: translateX(-540px);
    }

    .button-group-wrapper {
      width: $modal-regular-width;
      padding: 15px 0;

      // adding because this is getting overwritten by the %btn extend for unknown reasons.
      .btn {
        padding: 7px $double-space;
      }
    }

    .modal-extra-wide + .button-group-wrapper {
      width: $modal-wide-width;
      text-align: center;
    }

    &.x-two-contents {
      .modal-content {
        padding: 0;
      }

      .button-group-wrapper {
        width: 520px;
      }
    }

    .b-substrate-shop .top-filters .b-search-box.x-substrate-shop {
      width: 100%;

      .search-text-input-wrapper {
        height: 39px;
        border: $border-width solid $color-light-grey;
        border-radius: 30px / 30px;

        .search-input {
          left: $single-space;
        }

        .clear-search-button {
          top: 3px;
        }
      }
    }
  }

  @include respond-to(tablet, mobile) {
    .modal-content {
      width: 100%;
    }

    &.x-active {
      left: 100%;
      width: 100%;
    }

    &.x-active-slide-in {
      transform: translateX(-100%);
    }

    .button-group-wrapper {
      box-sizing: border-box;
      width: 100%;
      padding: $double-space;
    }

    .b-wallpaper-calculator-form .calculator-button-group-wrapper {
      width: 100%;
    }

    .modal-extra-wide {
      width: auto;
      padding: $double-space;
    }

    .modal-content.modal-extra-extra-wide {
      max-width: $modal-tablet-extra-extra-wide-width;
    }

    .btn-close {
      margin-right: 0;
    }
  }

  @include respond-to(mobile) {
    .modal-content.modal-with-button.modal-extra-wide {
      box-sizing: border-box;
      width: 100%;
    }

    // adding because this is getting overwritten by the %btn extend for unknown reasons.
    .button-group-wrapper.x-secondary {
      .white-button {
        margin-bottom: $single-space;
      }
    }

    // adding because this is getting overwritten by the %btn extend for unknown reasons.
    .big-button {
      padding: $single-space $double-space;
    }
  }

  position: fixed;
  z-index: $z-level-6;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  width: 100%;
  background-color: rgba($color-light-grey, .9);

  &.x-active {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .modal-content {
      display: none;
    }
  }

  &.x-active-slide-in {
    transition: transform .3s ease 0s;

    .modal-content {
      display: block;
    }
  }

  &.x-two-contents {
    .modal-content {
      padding: 0;
    }
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: inline-block;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: $single-half-space;

    &:hover .ico,
    &:focus .ico,
    &[data-focus-visible] .ico {
      color: $color-blue;
      box-shadow: 0 0 0 3px rgba(66, 153, 225, .6); // var(--chakra-shadows-outline)
      border-radius: $border-radius-curved;
      outline: 2px solid transparent;
      outline-offset: 2px;
      width: $quadruple-space;
    }

    .ico {
      box-shadow: 0 0 0 3px transparent;
      padding: 0;
      font-size: $content-font-size-xs;
      line-height: 44px;
      vertical-align: top;
    }
  }

  .modal-content {
    position: relative;
    overflow-y: auto;
    height: 100%;
    padding-top: $double-space;
    background-color: $color-white;

    &.touchscroll {
      // Added for scrolling Fabric Selection on IOS
      -webkit-overflow-scrolling: touch;
    }
  }

  .button-group-wrapper {
    position: fixed;
    z-index: $z-level-1;
    right: 0;
    bottom: 0;
    border-top: 2px solid $color-light-grey;
    background-color: $color-white;

    &.x-secondary {
      position: absolute;
      transition: transform .3s ease 0s;
      transform: translateX(0);

      .white-button {
        // adding because border values are overwritten by the %btn extend for unknown reasons
        border: $border-width solid $color-blue;
        border-radius: $border-radius-curved;
        margin-right: $double-space;
      }
    }
  }

  &.x-centered {
    opacity: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: $double-space;
    animation: flash .2s ease-out .1s 1 forwards;

    .modal-content {
      width: auto;
      max-height: max-content;
      padding-top: 0;
      background-color: rgba($color-white, 0);
    }

    .btn-close {
      top: 0;
      right: 0;
      width: 20px;
      height: 20px;
      background-color: rgba($color-white, .7);
    }

    .ico {
      line-height: $double-space;
    }
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.radio-group-mui {
  .radio-group-label {
    margin-bottom: $single-space;
    font-size: $content-font-size-s;
    text-align: left;
  }

  .radio-group-error {
    margin-bottom: $triple-space;
    font-style: italic;
    color: $color-red;
  }

  .Mui-checked ~ .MuiFormControlLabel-label {
    font-weight: $content-font-weight-bold;
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }

  .MuiButtonBase-root {
    margin: 5px 0;
    padding: 6px;
  }

  .MuiFormControlLabel-label {
    font-family: $font-primary;
    text-transform: capitalize;
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Filter
 */

.b-filter {
  border-top: $border-width solid $color-light-grey;
  padding-top: 15px;
  padding-bottom: 15px;

  .btn {
    text-align: left;
  }

  .link-button,
  .blue-button {
    display: block;
    margin: $double-space auto;
    text-align: center;
  }
}

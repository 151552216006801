
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

.b-subscribe {
  @include cdn-background-url("bg-subscribe-footer.png");

  position: relative;
  padding: 65px $double-space;
  text-align: center;
  background-size: cover;

  .subscribe-link {
    height: auto;
    margin-bottom: $double-space;
    padding: 12px $triple-space;
    font-size: $content-font-size-s;
    font-weight: $content-font-weight-light;
    line-height: $content-line-height-s;
  }

  .join-info {
    display: block;
    font-weight: $content-font-weight-light;
    color: $color-white;
  }
}

/**
 * Grid
 *
 * 50% - x-50
 * 33/66% - x-3366
 * 66/33% - x-6633
 * 33% - x-33
 * 25% - x-25
 */

.grid {
  @include respond-to(desktop) {
    .grid-item {
      float: left;
      margin: .5%;
    }

    &.x-3366 .grid-item {
      width: 32%;

      + .grid-item {
        width: 66%;
        margin-right: 0;
      }
    }

    &.x-6633 .grid-item {
      width: 64%;
      margin-right: 2%;

      + .grid-item {
        width: 34%;
        margin-right: 0;
      }
    }

    &.x-50 .grid-item {
      width: $grid-x-50;
    }

    &.x-33 .grid-item {
      width: $grid-x-33;
    }

    &.x-25 .grid-item {
      width: $grid-x-25;
    }
  }

  @include respond-to(tablet-down) {
    .grid-item {
      width: 100%;
      margin: 1%;
    }
  }

  @include clearfix;

  position: relative;

  .grid-item {
    overflow: hidden;
    box-sizing: border-box;
    padding: $single-space;

    &.x-no-padding {
      padding: 0;
    }

    &.x-show-overflow {
      overflow: visible;
    }
  }

  .x-shipping & .left-column {
    text-align: center;
  }
}

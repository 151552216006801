
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

.b-login-join {
  display: inline-block;
  height: $header-space;

  &.x-menu {
    display: block;
    height: 56px;
    padding: 0 $double-space;
    line-height: 56px;
    white-space: nowrap;
    background-color: $color-lightest-grey;

    .link-button {
      display: inline-block;
      margin: 0;
      font-size: $content-font-size-m;
      line-height: $content-line-height-m;
    }
  }

  .b-user-navigation & .link-button {
    padding-bottom: 6px;
    font-size: $base-font-size;
    line-height: $content-line-height-xxsh;
  }

  .link-button {
    color: $color-black;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: $color-black;
    }
  }

  .connecting-text {
    display: inline-block;
    padding: 0 $half-space;
    font-size: $content-font-size-m;
    font-weight: $content-font-weight-light;
    line-height: $content-line-height-m;
    color: $color-mid-grey;
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * General Loading
 */

.b-loading {
  padding: $double-space 0;
  text-align: center;

  .message {
    @include loading;

    display: inline-block;
    padding: 40px 1px 0;
    font-size: $content-font-size-s;
    line-height: $content-line-height-s;

    &::before {
      top: -4px;
      left: 50%;
      margin-left: -17px;
    }
  }
}

/*
 * Thumbnails
 */

.b-thumbnails {
  @include respond-to(small-desktop-down) {
    display: none;
  }

  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  z-index: $z-level-1;
  display: inline-block;
  max-height: 500px;
  padding-right: $double-space;
  vertical-align: top;

  &.x-spacing-wide {
    padding-right: $double-space;
  }

  &.x-square-image-page {
    padding-right: 15px;
  }

  .swiper-slide {
    box-sizing: border-box;
    width: 55px;
    height: 55px;
    border: $medium-border-width solid $color-lighter-grey;

    &:hover,
    &:focus {
      border-color: $color-black;
    }

    &.swiper-slide-thumb-active {
      border: $medium-border-width solid $color-black;
    }

    .swiper-image {
      width: 100%;
      height: auto;
    }
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Header Secondary
 */
header.b-header-secondary {
  @include respond-to(desktop, tablet) {
    justify-content: space-between;
  }

  @include respond-to(mobile) {
    justify-content: center;

    .logo {
      margin-bottom: $triple-space;
    }
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.x-center-logo {
    justify-content: center;
  }

  .logo {
    margin: $triple-space 2px $triple-space 0;

    &:focus {
      outline: $border-width dotted $color-light-grey;
    }
  }
}

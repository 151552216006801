/**
 * Basic Formattings
*/

html {
  font-size: $base-font-size;
}

body {
  overflow-x: auto;
  min-width: $break-mobile-min + px;
  font-family: $font-primary;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: $base-line-height;
  text-align: left;
  text-rendering: optimizelegibility;
  color: $color-black;
  background-color: $color-white;

  &::before {
    // send breakpoints to react
    @include visuallyhidden;
    /* stylelint-disable max-line-length */
    content: "{\"mobile\":#{$break-mobile-max}, \"tablet\":#{$break-tablet-max}, \"desktop\":#{$break-small-desktop-min}, \"large-desktop\":#{$break-large-desktop-min}}";
  }

  &.display-modal {
    @include respond-to(large-desktop) {
      // accounting for space of missing scroll bar, to avoid jumping behaviour of page content
      margin-right: 16px;
    }

    overflow: hidden;
  }
}

nav * {
  box-sizing: border-box;
}

.main {
  @include respond-to(large-desktop) {
    padding: $single-space $app-horizontal-space-desktop $double-space;
  }

  @include respond-to(small-desktop) {
    padding: $single-space $app-horizontal-space-tablet $double-space;
  }

  @include respond-to(tablet-down) {
    padding: $single-space $app-horizontal-space-mobile $double-space;
  }

  box-sizing: border-box;
  width: 100%;
  background-color: $color-white;

  &.x-mobile-nav-hide-transition {
    opacity: 0;
    transition: opacity .3s ease 0s;
  }

  &.x-mobile-nav-show-transition {
    opacity: 1;
    transition: opacity .3s ease 0s;
  }

  &.x-mobile-nav-show {
    opacity: .3;
    display: block;
  }

  .content-inner {
    @include clearfix;

    position: relative;
    max-width: $chrome-width-675;
    margin: 0 auto;

    .#{$fallback-ie9-class} & {
      width: $chrome-width-675;
    }

    &.x-1072 {
      max-width: $chrome-width-1072;

      .#{$fallback-ie9-class} & {
        width: $chrome-width-1072;
      }
    }

    &.x-1294 {
      max-width: $chrome-width-1294;

      .#{$fallback-ie9-class} & {
        width: $chrome-width-1294;
      }
    }
  }
}

.chrome-width {
  @include clearfix;

  box-sizing: border-box;
  max-width: $chrome-width-1728;
  margin: 0 auto;

  @include respond-to(extra-large-desktop) {
    padding: 0 $app-horizontal-space-desktop;
  }
}

/**
 * General text renderings
 */

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

button,
input,
textarea {
  font-family: $font-primary;
  color: $color-black;
}

.bullet-list {
  list-style: disc outside;

  li {
    margin-left: 15px;
    padding-left: $single-space;
  }
}

.secondary-information {
  font-style: italic;
  color: $color-mid-grey;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: $single-space;
}

.h1 {
  @include respond-to(tablet-down) {
    font-size: $content-font-size-xl;
    line-height: $content-line-height-xl;
  }

  font-size: $content-font-size-xxxl;
  font-weight: 300;
  line-height: $content-line-height-xxxl;
}

.h2 {
  font-size: $content-font-size-xl;
  line-height: $content-line-height-xl;
}

.h3 {
  font-size: $content-font-size-m;
  line-height: $content-line-height-m;
}

.h4 {
  font-size: $content-font-size-s;
  line-height: $content-line-height-s;
}

/**
 * Hyperlinks
 */

a:hover,
a:focus,
a:hover span,
a:focus span {
  outline: 0 none;
  color: $color-dark-blue;
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: $color-blue;

  &.blue-button,
  &.green-button,
  &.black-button {
    color: $color-white;
  }
}

.link-text {
  text-decoration: underline;
}

/**
 * Icons
 */

.ico-chevron-down {
  padding: 8px 0 0 $half-space;
  font-size: $content-font-size-xxxs;
  vertical-align: top;
}

.ico-close {
  padding: 2px 0 0 $half-space;
  font-size: $content-font-size-xxs;
}

@include respond-to(tablet-down) {
  .ico-studio {
    font-size: $content-font-size-l;
  }
}

.ico-checkmark.x-big {
  font-size: 54px;
  line-height: 54px;
  text-shadow: 1px 0 1px $color-black;
  color: $color-white;
}

/**
 * Standards & Helpers
 */

/* hide it on small (phones & tablets) screens */
.mobile-hide {
  @include respond-to(mobile) {
    display: none !important;
  }
}

.tablet-hide {
  @include respond-to(tablet) {
    display: none !important;
  }
}

.tablet-down-hide {
  @include respond-to(tablet-down) {
    display: none !important;
  }
}

.small-desktop-down-hide {
  @include respond-to(small-desktop-down) {
    display: none !important;
  }
}

/* hide it on desktop screens */
.desktop-hide {
  @include respond-to(desktop) {
    display: none !important;
  }
}

/* hide it on large desktop screens */
.large-desktop-hide {
  @include respond-to(large-desktop) {
    display: none !important;
  }
}

.space {
  margin: $single-space;
}

.space-right {
  margin-right: $single-space;
}

.space-top {
  margin-top: $single-space;
}

.sm-space-top {
  margin-top: 4px;
}

.space-bottom {
  margin-bottom: $single-space;
}

.space-bottom-large {
  margin-bottom: $double-space;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.display-inline-block {
  display: inline-block;
}

.padding-wrapper {
  overflow: hidden;
  padding: $double-space 0;
}

.x-fat-border {
  border: $thick-border-width solid $color-lighter-grey;
  border-radius: $border-radius-curved;
}

.x-shadowed {
  box-shadow: $item-box-shadow;
  border: $border-width solid $color-lighter-grey;
}

.b-design-item,
.b-color-item {
  &.x-shadowed:focus {
    box-shadow: $item-box-shadow-highlight;
  }
}

.clearfix {
  @include clearfix;
}

.ellipsis {
  @include ellipsis;
}

.hyphens {
  @include hyphens;
}

.show {
  @include show;
}

.hide {
  @include hide;
}

.visuallyhidden {
  @include visuallyhidden;
}

/* chat "Need Help icon optimization for better performance metrics" */
.floating-chat-button__icon {
  img {
    width: 23px;
    height: 25px;
  }
}

@include print {
  .floating-chat-button {
    display: none;
  }

  .exitintel-wrapper {
    display: none;
  }
}

/* react-select style overrides shared by all instances */
.x-react-select {
  // Note that the classes below are all class names forced upon us by the react-select package.
  // Normally classnames of ours follow a different pattern.
  .react-select__control {
    border-color: $color-mid-grey;
    border-radius: $border-radius-none;

    .react-select__indicator-separator {
      width: 0;
      background-color: transparent;
    }

    .react-select__value-container {
      height: 33px;
      padding: 0 $single-space;
    }

    &.react-select__control--is-focused {
      box-shadow: none;
      border-color: $color-mid-grey;
    }

    // react-select class name for down chevron
    .css-6q0nyr-Svg {
      width: 16px;
      height: 16px;
      color: $color-black;
    }
  }

  .x-error {
    .react-select__control {
      border-color: $color-red;
    }
  }

  .react-select__menu {
    z-index: $z-level-2;
    margin-top: 0;
    box-shadow: none;
    border: $border-width solid $color-light-grey;
    border-radius: $border-radius-none;

    .react-select__option--is-focused {
      color: $color-black;
      background-color: $color-lightest-grey;
    }

    .react-select__option--is-selected {
      color: $color-black;
      background-color: $color-robins-egg-blue;
    }
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";
@import "src/stylesheets/mixins";

/*
 * Substrate Filters
 */

.b-substrate-filter {
  @include respond-to(desktop) {
    float: left;
    width: 220px;
    margin-right: $double-space;
  }

  @include respond-to(tablet-down) {
    .filter-heading-group {
      margin-top: $double-space;
      padding: 0 0 $single-space;
    }

    .clear-filters {
      width: auto;
    }

    .b-filter {
      padding-top: $single-space;
    }
  }

  .b-filter {
    .x-filter-subheading {
      height: 44px;
      margin-top: $single-space;
      font-size: $content-font-size-xs;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-xs;
      text-transform: uppercase;
      color: $color-black;
    }

    .nav-link:hover,
    .nav-link:focus {
      text-decoration: underline;
    }
  }

  .clear-filters {
    margin: $triple-space 0 $half-space;

    &:hover .clear-filters-text,
    &:focus .clear-filters-text {
      text-decoration: underline;
    }

    .clear-filters-text {
      font-size: $content-font-size-xs;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-xs;
      text-transform: uppercase;
      color: $color-blue;
    }
  }
}

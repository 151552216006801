
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Mobile Navigation Background
 */

.b-mobile-navigation-cover {
  position: absolute;
  z-index: $z-level-7;
  inset: 0;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  background-color: rgba($color-lightest-grey, .7);
  transition: transform .3s ease 0s;
  transform: translateX(0);

  &.x-hidden {
    transform: translateX(-100%);
  }

  &.b-filter {
    padding-top: 0;
  }

  .btn-close {
    position: absolute;
    z-index: $z-level-6;
    top: 0;
    left: 268px;
    width: 52px;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background-color: $color-white;

    &:focus .ico {
      color: $color-deep-black;
    }

    .ico {
      padding: 0;
      font-size: $content-font-size-xs;
      color: $color-dark-grey;
    }
  }

  .b-mobile-navigation,
  .b-substrate-filter,
  .b-search-filters {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    box-sizing: border-box;
    width: 320px;
    box-shadow: $item-box-shadow;
    background-color: $color-white;
  }

  .b-substrate-filter {
    padding-left: $double-space;
  }

  .b-search-filters {
    padding: $quadruple-space $double-space 0;
  }
}

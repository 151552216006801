
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

span.ant-switch-mui {
  width: 26px;
  height: 16px;
  margin: 11px;
  padding: 0;
  display: flex;

  &:active {
    & .MuiSwitch-thumb {
      width: 10px;
    }

    & .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(9px);
    }
  }

  & .MuiSwitch-switchBase {
    padding: 2px;

    &.Mui-checked {
      transform: translateX(10px);
      color: $chakra-warm-white;

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: $chakra-teal-500;
      }

      & span.MuiSwitch-thumb {
        opacity: 1;
        border: 1px solid $chakra-warm-white;
      }
    }
  }

  & .MuiSwitch-thumb {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, .2);
    width: 10px;
    height: 10px;
    border-radius: 6px;
    transition: width .2s;
    border: 1px solid $chakra-gray-700;
  }

  & .MuiSwitch-track {
    border-radius: 8px;
    opacity: 1;
    border: 1px solid $chakra-gray-700;
    box-sizing: border-box;
    background-color: $chakra-warm-white;
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/variables";

/*
 * Flash messages
 */

.b-flash-messages {
  overflow: hidden;
  opacity: 0;
  position: relative;

  .#{$fallback-ie9-class} & {
    opacity: 1;
  }

  // when visible
  &[class*="x-"] {
    border: 1px solid transparent;
    animation: flash .5s ease-out .2s 1 forwards;

    .message {
      padding: $single-space $double-space;
      color: $color-white;

      a {
        text-decoration: underline;
        color: $color-white;
      }
    }
  }

  &.x-no-severity {
    margin-top: $quadruple-space;
    border-radius: $border-radius-curved;
    text-align: left;
    background-color: $color-black;

    .message {
      margin-left: 0;
      padding: $double-space;
      font-size: $content-font-size-s;
      font-weight: $content-font-weight-light;
      text-align: left;
    }
  }

  // types: error, success, info
  &.x-error {
    border-color: darken($color-red, 5%);
    background-color: $color-red;
  }

  &.x-success {
    border-color: darken($color-green, 5%);
    background-color: $color-green;
  }

  &.x-info {
    border-color: darken($color-blue, 5%);
    background-color: $color-blue;
  }

  &.x-attention {
    border-color: darken($color-yellow, 5%);
    background-color: $color-yellow;
  }

  &.x-iewarning {
    display: none;

    .ie8 & {
      display: block;
    }
  }

  &.x-nojs {
    .js & {
      display: none;
    }
  }

  .message {
    max-width: 680px;
    margin: 0 auto;
    font-weight: $content-font-weight-bold;
    text-align: center;
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

.filter-wrapper {
  padding: $single-space 0;

  .custom-collapsed-content {
    flex-wrap: wrap;
  }

  .MuiTreeItem-content,
  .collapsed-tag {
    width: fit-content;
    padding: 3px 15px;
    font-weight: 400;

    &-wrapper {
      flex-basis: 100%;
      order: 3;
    }
  }

  .collapsed-tag {
    display: flex;
    align-items: center;
    margin: $single-space 0 0 15px;
    border-radius: 20px;
    font-size: 14px;
    line-height: $content-line-height-xs;
    color: $color-deep-black;
    background-color: $color-lighter-grey;
  }

  .MuiTreeView-root {
    overflow: hidden;
    width: 100%;

    > .MuiTreeItem-root {
      border-top: $border-width solid $color-light-grey;
      padding: $double-space 0;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }
  }

  .MuiTreeItem {
    &-root {
      width: unset;
      font-family: $font-primary;

      &[aria-expanded="false"] {
        .MuiTreeItem-content {
          align-items: start;
          justify-content: start;
        }
      }
    }

    &-content {
      flex-direction: row-reverse;
      align-content: center;
      justify-content: start;
      width: fit-content;
      margin: 6px 0;
      color: $color-dark-grey;
      transition: all .3s;

      &:hover {
        border-radius: 20px;
        color: $color-deep-black;
        background-color: transparent;
      }

      .MuiTreeItem-label {
        width: unset;
        margin-right: 12px;
        font-family: $font-primary;
        font-size: 14px;
        line-height: $content-line-height-xs;
      }

      &.Mui-selected {
        border-radius: 20px;
        font-weight: 700;
        color: $color-deep-black;
        background-color: $color-lighter-grey;

        &:hover {
          background-color: $color-lighter-grey;
        }

        &.Mui-focused {
          background-color: $color-lighter-grey;
        }
      }

      &.Mui-focused {
        background-color: unset;
      }
    }
  }

  &[data-collapsible="collapsible"] {
    font-weight: 400;

    .Mui-expanded {
      .MuiTreeItem-label {
        font-weight: 700;
      }
    }
  }

  .Mui-expanded ~ div {
    opacity: 1 !important;
  }

  .MuiTreeItem-label > .seo-label {
    color: inherit;
    text-decoration: none;
  }

  .cross-icon {
    margin-top: -1px;
    margin-left: 5px;

    &:hover {
      color: $color-dark-grey;
    }
  }

  .Mui-expanded {
    height: 20px;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    color: $color-deep-black;
  }

  .parentCategory-content {
    margin: $single-space, 0, 0, 0;
    padding: 0;
    color: $color-deep-black;

    .MuiTreeItem-label {
      font-family: $font-primary;
      font-size: $content-font-size-s;
      line-height: $content-line-height-xs;
    }
  }

  .MuiCollapse-root {
    margin-left: $single-space;
  }
}

/*
 * Search Filters
 */
.filter-header-title {
  margin-bottom: $single-space;
  border-bottom: $border-width solid $color-light-grey;
  padding-bottom: $double-space;
  font-size: $content-font-size-m;
  font-weight: $content-font-weight-bold;
  line-height: $content-line-height-m;
  text-align: left;
}

.b-search-filters {
  @include respond-to(large-desktop) {
    padding-right: $hextuple-space;
  }

  @include respond-to(small-desktop) {
    padding-right: $triple-space;
  }

  @include respond-to(tablet) {
    .sort-by-selector {
      @include clearfix;

      .x-react-select {
        float: right;
        display: inline-block;
        width: 49%;

        &:first-child {
          float: none;
        }
      }
    }

    .label {
      height: 22px;
    }
  }

  @include respond-to(tablet-down) {
    .sort-by-selector {
      padding-bottom: $quadruple-space;

      .label {
        display: block;
        padding-bottom: 14px;
        font-size: $content-font-size-s;
        font-weight: $content-font-weight-bold;
        line-height: $content-line-height-s;
      }

      .react-select__control {
        border-radius: $border-radius-none;
      }

      .h3 {
        font-weight: $content-font-weight-bold;
      }
    }

    .blue-button {
      font-weight: $content-font-weight-normal;
    }

    .b-filter {
      &.show-menu .x-selected-parent-element {
        border-bottom: $border-width solid $color-light-grey;
      }

      .mobile-nav-list {
        padding: 0;
      }

      .nav-link.child-link {
        @include ellipsis;
      }
    }
  }

  overflow: hidden;
  display: inline-block;
  width: 280px;
  vertical-align: top;

  .filter-actions-wrapper .filter-button + .filter-button {
    margin-left: $single-space;
  }

  .b-filter-items {
    padding-top: 0;

    &.x-back-button {
      .x-search-filter-columns,
      .x-parent-element {
        padding-left: $double-space;
      }

      .filter-selected-menu {
        white-space: nowrap;
      }
    }

    > .x-parent-element > .x-nav-link {
      display: flex;
      flex-wrap: wrap;

      .filter-text-title-menu {
        flex-basis: 100%;
      }
    }

    .x-selected-parent-element,
    .x-parent-element,
    .x-search-filter-columns {
      padding-left: 0;
    }

    .x-selected-parent-element ~ .x-search-filter-columns,
    .x-selected-parent-element ~ .x-parent-element {
      padding-left: $single-space;
    }

    .x-selected-parent-element + .x-search-filter-columns,
    .x-selected-parent-element + .x-parent-element {
      margin-top: -10px;
    }

    .x-selected-element {
      padding-left: $double-space;
    }

    .x-search-filter-columns .nav-link {
      white-space: normal;
    }

    .x-selected-parent-element {
      position: relative;

      .icon-text {
        position: relative;
        display: inline-block;
        height: 36px;
        vertical-align: top;
      }
    }

    .list-element:last-child:not(:first-child) {
      padding-bottom: 15px;
    }

    .nav-link {
      position: relative;
      display: inline-block;
      height: 36px;
      margin-top: 0;
      padding-left: $double-space;
      line-height: 1;
      vertical-align: top;

      &:hover .filter-text-title-menu,
      &:focus .filter-text-title-menu,
      &:hover .color-text,
      &:focus .color-text {
        text-decoration: underline;
      }

      &.x-old-color-button {
        height: 40px;
      }

      > .ico {
        position: absolute;
        top: 8px;
        left: 0;
        font-weight: $content-font-weight-bold;

        &.ico-chevron-down {
          padding: 0;
        }
      }

      .filter-text-title-menu {
        display: block;
        font-size: $content-font-size-s;
        font-weight: $content-font-weight-normal;
        line-height: $content-line-height-s;
      }

      .filter-text-sub-title-menu {
        display: inline-block;
        font-size: $content-font-size-xxsh;
        line-height: $content-line-height-xxxxs;
        text-transform: uppercase;
        color: $color-mid-grey;
      }

      .filter-text-entry-menu {
        font-weight: $content-font-weight-light;
        line-height: $content-line-height-xxsh;
      }
    }
  }

  .subtitle {
    margin-top: 0;
  }

  .nav-link.button.active {
    font-weight: $content-font-weight-bold;
  }

  .sub-heading,
  .filter-group-wrapper {
    border-top: $border-width solid $color-light-grey;
    border-bottom: $border-width solid $color-light-grey;
    padding: 15px 0;

    .radio-group-label {
      color: $chakra-gray-700;
      font-size: $content-font-size-s;
    }

    .MuiFormGroup-root {
      margin: 0 0 0 -7px;
    }
  }

  .filter-group-wrapper:nth-child(3) {
    border-top: none;
  }

  .filter-group-label {
    font-family: $font-primary;
    text-transform: capitalize;
    margin-bottom: $single-space;
    font-size: $content-font-size-s;
    text-align: left;
    color: $chakra-gray-700;
  }

  .filter-group-control .MuiFormControlLabel-label {
    color: $color-gray-pressed;
    font-size: $content-font-size-xshh;
  }

  .embedded-filter-wrapper {
    border-top: $border-width solid $color-light-grey;
    padding: $double-space 0;
  }
}

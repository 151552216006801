
  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * Dot
 */

.b-dot {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 14px;
  margin-top: 3px;
  padding-right: $half-space;
  white-space: nowrap;
  color: transparent;

  &::before {
    box-sizing: border-box;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: $half-space;
    border-radius: 14px;
    content: "";
  }

  @each $color in black brown grey yellow green blue purple pink red orange white {
    &.x-#{$color}:before {
      background-color: $color;
    }
  }

  &.x-all-colors::before {
    background:
      linear-gradient(217deg, rgba(255, 0, 0, .8), rgba(255, 0, 0, 0) 70.71%),
      linear-gradient(127deg, rgba(0, 255, 0, .8), rgba(0, 255, 0, 0) 70.71%),
      linear-gradient(336deg, rgba(0, 0, 255, .8), rgba(0, 0, 255, 0) 70.71%);
  }

  &.x-white::before {
    border: $border-width solid $color-light-grey;
  }
}

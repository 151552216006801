/*
 * Forms
 */

.b-form {
  @include respond-to(desktop) {
    .label,
    label {
      display: inline-block;
      margin-right: $single-space;
      padding-top: 7px;
      vertical-align: top;
    }

    .text {
      width: 60%;
      margin: 0 0 $single-space 0;
      border: $border-width solid $color-mid-grey;
      font-size: $content-font-size-s;
    }

    .type-text,
    .type-check {
      &.x-loading::before {
        top: 0;
        left: 0;
      }
    }

    &.x-shipping {
      display: inline-block;
      width: 62%;
      margin-left: 30px;
      padding-right: 5%;

      .label {
        max-width: 144px;
        padding-top: 0;
        line-height: $content-line-height-xs;
      }

      .text {
        width: 230px;
      }

      .x-react-select {
        .react-select__menu {
          border-color: $color-mid-grey;
        }

        .x-input-size-select {
          margin-top: 0;
        }
      }
    }

    // Select Styles
    .x-react-select {
      .Select {
        display: inline-block;
        margin-top: $half-space;
      }

      .react-select__control {
        z-index: $z-level-1;
        height: 29px;
      }

      .react-select__placeholder .react-select__control {
        border: $border-width solid $color-mid-grey;
        padding: 0 25px 0 $half-space;
      }

      .react-select__menu-outer {
        z-index: $z-level-3;
        margin-top: 8px;
      }

      .react-select__placeholder .react-select__control,
      .react-select__placeholder,
      .react-select__input,
      .react-select__input input,
      .react-select__option,
      .react-select__value-label {
        height: 27px;
        line-height: 27px;
      }

      // Select Helpers

      &.x-two-vertical-selects-1 {
        .react-select__control,
        .react-select__menu-outer {
          z-index: $z-level-4;
        }
      }

      &.x-select-in-vertical-form {
        margin-bottom: 15px;
      }

      .x-input-size-select {
        width: 230px;
      }
    }

    // Specific Forms
    &.x-currency {
      padding: 0;

      .Select {
        width: 100px;
      }
    }

    &.x-paypal {
      .paypal-button {
        width: 266px;
        padding: $double-space;

        &.x-loading {
          cursor: pointer;
          pointer-events: none;

          &::before {
            top: 17px;
            left: 21px;
          }
        }

        img {
          padding-left: 7px;
        }

        &.x-active {
          padding: $half-space $double-space;
          transition: padding .4s ease .2s;

          img {
            max-height: 26px;
            transition: max-height .4s ease .2s;
          }
        }
      }
    }

    // TODO: EN-1134 - clean up payment styling architecture
    &.x-payment {
      .type-text,
      .x-react-select {
        .label {
          width: 36%;
          margin-right: 4%;
        }

        .text,
        .Select {
          display: inline-block;
          vertical-align: top;
        }

        .x-input-size-select {
          width: 60%;
          margin-top: 0;
        }
      }

      .x-short {
        .Select {
          width: 29%;

          .react-select__menu-outer {
            width: 99%;
          }
        }

        .Select + .Select {
          margin-left: 6px;

          .react-select__menu-outer {
            width: 100%;
          }
        }
      }
    }

    .helper-text {
      text-align: right;
    }

    .payment-half {
      padding: 0 $double-space 0 0;

      &:last-child {
        padding-right: 0;
      }
    }

    .additional-info {
      padding-left: $single-space;
    }

    &.x-search-collections-wrapper {
      margin: $quadruple-space $quadruple-space 0;
    }
  }

  @include respond-to(tablet-down) {
    width: 100%;

    .label {
      display: block;
    }

    .type-checkbox .label,
    .type-radio .label {
      display: inline-block;
    }

    .type-text {
      text-align: left;
    }

    .text {
      width: 100%;
      margin: $half-space 0 $single-space 0;
      border: $border-width solid $color-mid-grey;
      font-size: $content-font-size-s;
      line-height: $content-line-height-l;
    }

    .type-text,
    .type-check {
      &.x-short-left.x-loading::before,
      &.x-loading::before {
        right: 3%;
        bottom: 13px;
      }
    }

    // Select Styles
    .x-react-select {
      width: 100%;
      margin-top: $half-space;

      .label {
        padding-bottom: $half-space;
      }

      .react-select__control {
        height: 38px;
      }

      .react-select__placeholder .react-select__control {
        border: $border-width solid $color-mid-grey;
        padding: 0 25px 0 $half-space;
      }

      .react-select__placeholder .react-select__control,
      .react-select__placeholder,
      .react-select__input,
      .react-select__input input,
      .react-select__option,
      .react-select__value-label {
        height: 36px;
        line-height: $content-line-height-xxl;
      }

      .react-select__input,
      .react-select__placeholder,
      .react-select__option,
      .react-select__value-label {
        font-size: $content-font-size-s;
      }

      .Select + .Select {
        margin-top: $single-space;
      }
    }

    // Form Element Helpers
    .x-select-in-vertical-form {
      float: right;
      margin-bottom: $double-space;
    }

    // Specific Forms
    &.x-currency {
      padding: $half-space 3px $half-space 0;
    }

    .payment-half,
    label {
      text-align: left;
    }

    &.x-search-collections-wrapper {
      margin-top: $triple-space;
    }

    &.x-shipping {
      text-align: left;
    }

    .newsletter-wrapper {
      display: inline-block;
    }
  }

  @include respond-to(mobile) {
    // Specific Forms
    .payment-types {
      width: 100%;
      height: auto;
    }

    &.x-delivery-form .type-radio .radio {
      margin-bottom: $single-space;
    }
  }

  box-sizing: border-box;
  padding: $single-space 0;
  text-align: right;

  // Form Helpers: To be placed on b-form
  &.x-left-align {
    text-align: left;
  }

  &.x-no-padding {
    padding: 0;
  }

  // Specific Forms
  &.x-currency .react-select--is-disabled .react-select__single-value,
  &.x-shipping .react-select--is-disabled .react-select__single-value {
    color: $color-black;
  }

  &.x-search-collections-wrapper {
    display: flex;
    justify-content: space-around;
    border: $border-width solid $color-light-grey;
    padding: 0 $half-space 0 $single-space;
    text-align: left;

    .x-search-input {
      flex: 1;
      outline: 0 none;
      margin: 0;
      border: 0 none;
      line-height: $content-line-height-xs;
    }

    .ico-search {
      margin: $single-space $half-space;
    }
  }

  &.x-create-collection-wrapper {
    .x-create-collection-text-input-wrapper {
      margin-bottom: $triple-space;
    }

    .x-create-creation-label {
      display: block;
      padding-top: 0;
      text-align: left;
      text-transform: uppercase;
      color: $color-mid-grey;
    }

    .x-create-collection-input,
    .x-create-collection-textarea {
      width: 100%;
      border: $border-width solid $color-light-grey;
      padding: $single-space;
      font-size: $content-font-size-xs;
      line-height: $content-line-height-xs;
    }

    .x-create-collection-textarea {
      height: 84px;
      margin-top: $half-space;
    }

    .x-create-collection-checkbox {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: $single-space;
      text-align: left;

      .label {
        width: auto;
        margin-left: $half-space;
        padding-top: 0;
        line-height: $content-line-height-xxsh;
      }
    }
  }

  // TODO: EN-1134 - clean up payment styling architecture
  &.x-payment {
    padding: $double-space;
    padding-bottom: 0;
  }

  .payment-half {
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.x-paypal {
    position: relative;
    padding: $double-space;
    padding-bottom: 0;
    text-align: left;

    .currency-text {
      margin-bottom: $double-space;
    }

    .b-inline-errors {
      margin-bottom: $single-space;
    }

    .paypal-button {
      user-select: none;
      position: relative;
      display: block;
      margin-bottom: $double-space;
      text-align: center;
      vertical-align: top;

      .ico {
        position: absolute;
        top: 50%;
        left: 10%;
        margin-top: -17px;
        font-size: $content-line-height-xl;
        line-height: 1;
        color: $color-green;
        transform: scale(0, 0);
        animation: zoom-in .5s ease-in 0s 1 forwards;
      }
    }
  }

  &.x-delivery-form {
    padding: 0;
    text-align: left;

    &.x-disabled {
      .radio,
      .checkbox,
      .textarea,
      .note,
      .link-button,
      label {
        border-color: $color-light-grey;
        color: $color-light-grey;
        pointer-events: none;
      }
    }

    .highlighted {
      box-sizing: border-box;
      padding: 15px;
      background-color: $color-lighter-grey;
    }

    .label {
      width: auto;
      padding-top: 7px;
      text-align: left;
    }

    .type-radio,
    .type-checkbox {
      position: relative;

      label {
        font-size: $content-font-size-s;
        line-height: $content-line-height-s;
      }

      .x-highlight {
        font-weight: 400;
        color: $color-green;
      }
    }

    .type-checkbox label {
      padding-top: 0;
    }

    .type-radio {
      padding: 0 0 0 $single-space;
    }

    .checkbox {
      margin-right: 7px;
    }

    .type-textarea {
      padding: 12px 0 0;

      .textarea {
        box-sizing: border-box;
        width: 100%;
        height: 70px;
        min-height: 70px;
        font-family: $font-primary;
        resize: vertical;
      }
    }
  }

  legend {
    text-align: left;
  }

  // placeholders
  ::input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color-mid-grey;
  }

  ::placeholder {
    opacity: 1;

    /* Mozilla Firefox 19+ */
    color: $color-mid-grey;
  }

  // TODO: EN-82 clean up select styling once react-select replaces all live selects
  // make sure also payment and other text fields are accounted for

  .text,
  .textarea {
    box-sizing: border-box;
    padding: $single-space;
    font-family: $font-primary;
    background-color: $color-white;

    &:focus {
      border-color: $color-black;
    }
  }

  .textarea {
    border: $border-width solid $color-mid-grey;
  }

  .x-bottom-dashed {
    margin-bottom: $single-space;
    border-bottom: $border-width dashed $color-mid-grey;
    padding-bottom: $single-space;
  }

  .text[disabled],
  .text[readonly] {
    border-color: $color-light-grey;
    color: $color-gray-500;
  }

  // needs to be separate to not break ie
  .x-disabled:not(button),
  .x-disabled.link-button,
  .x-disabled .label {
    border-color: $color-light-grey;
    color: $color-gray-500;
  }

  .form-title {
    font-family: $font-primary;
    font-size: $content-font-size-s;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    color: $color-black;
  }

  .type-text,
  .type-check {
    position: relative;
    clear: both;
    margin-bottom: $half-space;
  }

  label,
  .label {
    transition: color .5s ease 0s;
  }

  fieldset {
    display: block;
    width: 100%;
    margin-bottom: $double-space;
  }

  .type-radio {
    @include clearfix;

    .radio {
      position: relative;
      top: 8px;
      float: left;
      margin-right: $single-space;

      + label {
        overflow: hidden;
      }
    }
  }

  // Select Styles
  .x-react-select {
    // Note that the classes below are all class names forced upon us by the react-select package.
    // Normally classnames of ours follow a different pattern.

    .react-select__control {
      border-color: $color-mid-grey;
      border-radius: $border-radius-none;
    }

    .react-select__placeholder {
      padding-left: $half-space;
      text-align: left;
      white-space: nowrap;
      color: $color-mid-grey;
    }

    .react-select__control .react-select__value {
      padding-left: $half-space;
    }

    .react-select__placeholder .react-select__control {
      border: $border-width solid $color-mid-grey;
      padding: 0 25px 0 $half-space;
      background-color: $color-white;
    }

    .react-select__value-label {
      float: left;
    }

    .react-select__option {
      overflow: hidden;
      padding: 0 0 0 $half-space;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .react-select__input {
      float: left;
      padding-left: $half-space;

      input {
        padding: 0;
      }
    }

    .is-focused:not(.is-open) > .react-select__control {
      // react-select "active" state when tabbing into it
      border-color: $color-blue;
    }

    .react-select--is-disabled {
      .react-select__control {
        border-color: $color-light-grey;
        background-color: $color-white;
      }

      &.react-select__single > .react-select__control {
        .react-select__value {
          .react-select__value-label {
            color: $color-light-grey;
          }
        }
      }

      &.x-faux-enabled .react-select__control {
        border-color: $color-mid-grey;

        .react-select__value .react-select__value-label {
          color: $color-black;
        }
      }
    }

    &.x-error {
      label {
        color: $color-red;
      }

      .react-select__control {
        border-color: $color-red;
      }
    }

    // Select Helpers
    .x-select-in-vertical-form-for-error {
      margin-top: $half-space;
    }
  }

  .x-highlight-box {
    overflow: hidden;
    position: relative;
    margin-bottom: $half-space;
    padding: $single-space 0;
    text-align: left;
    background-color: $color-lighter-grey;

    .checkbox {
      float: left;
      width: 15%;
      max-width: 40px;
      margin: $half-space 0 0 5%;
      text-align: left;
    }

    .label {
      overflow: hidden;
      display: inline-block;
      width: 80%;
      margin: 0;
      padding-top: 0;
      text-align: left;
      vertical-align: top;
    }
  }

  .helper-text {
    margin: -5px 0 $single-space 0;
    font-family: $font-secondary;
    font-size: $content-font-size-xs;
    font-style: italic;
    line-height: $content-line-height-xs;
  }

  // emarsys guest opt in
  .newsletter-wrapper {
    position: relative;

    .type-checkbox {
      padding-right: $double-space;

      .checkbox {
        margin: 0 7px 0 0;
        line-height: $content-line-height-s;
      }

      .label {
        max-width: none;
        margin-right: $half-space;
        padding-top: 1px;
      }
    }

    .b-helper-tooltip {
      .trigger {
        top: -18px;
      }

      .description {
        max-width: 270px;
        margin-top: 0;
        padding-right: 0;
        font-family: $font-secondary;
        font-size: $content-font-size-xs;
        font-style: italic;
      }
    }
  }
}


  $break-extra-large-desktop: 1808;
  $break-large-desktop-min: 1025;
  $break-small-desktop-max: 1024;
  $break-small-desktop-min: 769;
  $break-tablet-max: 768;
  $break-tablet-min: 497;
  $break-mobile-max: 496;
  $break-mobile-min: 320;
  $break-vertical-tablet-down: 680;
  $break-vertical-desktop: 681;

@import "src/stylesheets/mixins";
@import "src/stylesheets/variables";

/*
 * Mobile Navigation Items
 */

.b-menu-items {
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding-bottom: 15px;
  background-color: $color-white;

  .list-element {
    position: relative;

    &.x-headline-element,
    &.x-parent-back-element {
      box-sizing: border-box;
      height: 52px;
      padding: 0 42px 0 $double-space;
      font-size: $content-font-size-s;
      line-height: 52px;

      .nav-link {
        padding-left: 0;
        line-height: 52px;

        .icon-text {
          display: inline-block;
          font-size: $content-font-size-xs;
          line-height: $content-line-height-xs;
          text-transform: uppercase;
        }

        .ico-chevron-left {
          display: inline-block;
          margin-right: $half-space;
          font-size: $content-font-size-xxxs;
          line-height: $content-line-height-xs;
        }
      }
    }

    &.x-headline-element {
      @include ellipsis;
    }

    &.x-user-element,
    &.x-guest-element,
    &.x-parent-headline-element {
      height: 56px;
      margin-bottom: 7px;
      padding: 0;
      font-size: $content-font-size-m;
      line-height: 56px;
    }

    &.x-parent-headline-element {
      padding-left: $double-space;
      font-weight: $content-font-weight-light;
      color: $color-white;
      background-color: $color-black;
    }

    &.x-user-element .link-button {
      display: block;
      height: 56px;
      padding: 0 $double-space;
      font-size: $content-font-size-m;
      line-height: 56px;
      text-align: left;
      white-space: nowrap;
      color: $color-black;
      background-color: $color-lightest-grey;

      &:focus {
        text-decoration: underline;
      }
    }

    &.x-emphasized .nav-link {
      background-color: $color-robins-egg-blue;
    }

    &.x-social-element,
    &.x-business-element {
      height: auto;
      padding-left: $double-space;
      line-height: $base-line-height;

      .b-social-links {
        margin-top: 5px;
      }
    }

    &.x-seperator-line {
      margin-top: 9px;
      border-top: $border-width solid $color-light-grey;
      padding-top: 15px;
    }

    &.x-business-element {
      padding-right: $double-space;
    }

    .nav-link {
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 100%;
      padding: 2px 0 2px $double-space;
      font-size: $content-font-size-s;
      line-height: $content-line-height-xxhl;
      text-align: left;
      color: $color-black;

      &:focus {
        background-color: $color-lightest-grey;
      }

      &.child-link {
        @include ellipsis;

        padding-right: 10px;
      }

      &[href*="recent"] {
        text-decoration: underline;
      }

      .ico-chevron-right {
        position: absolute;
        top: 0;
        right: 21px;
        font-size: $content-font-size-xxs;
        line-height: $content-line-height-xxxl;
      }
    }
  }

  .upload-button {
    width: fit-content;
    margin: $single-space 0 $single-space 20px;

    .blue-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 31px;
      margin-bottom: 29px;
      padding: 0 16px;
      font-size: $content-font-size-xs;
      font-weight: $content-font-weight-light;
      text-transform: uppercase;

      &:hover .ico,
      &:focus .ico {
        color: $color-white;
      }

      .ico {
        margin-right: 8px;
        font-size: $content-font-size-xs;
      }
    }
  }

  .my-dashboard-element {
    margin-bottom: $single-space;

    &:hover {
      .user-name {
        color: $color-black;
      }
    }

    .nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: -7px;
      padding: 15px;
      background-color: $color-robins-egg-blue;
    }

    .user-name {
      @include ellipsis;

      display: block;
      width: 90%;
      margin-bottom: 8px;
      font-size: $content-font-size-s;
      font-weight: $content-font-weight-bold;
      line-height: $content-line-height-s;
      text-align: center;
    }

    .my-dashboard {
      margin-bottom: 6px;
      font-size: $content-font-size-xs;
      line-height: 15px;
      text-decoration: underline;
      color: $color-black;
    }
  }
}

/**
 * ---------------------------------------------------------------------------------------------------- #
 *
 * Fonts from production bucket
 *
 *
 */

@font-face {
  font-family: spoonflower-icons;
  src:
    url("#{$cdn-url}/production/fonts/spoonflower-icons.eot?20240531#iefix") format("embedded-opentype"),
    url("#{$cdn-url}/production/fonts/spoonflower-icons.ttf?20240531") format("truetype"),
    url("#{$cdn-url}/production/fonts/spoonflower-icons.woff?20240531") format("woff"),
    url("#{$cdn-url}/production/fonts/spoonflower-icons.svg?20240531#spoonflower-icons") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ParryPro;
  src:
    local("ParryPro"),
    local("Parry Pro"),
    url("#{$cdn-url}/production/fonts/ParryPro-Normal.woff") format("woff"),
    url("#{$cdn-url}/production/fonts/ParryPro-Normal.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ParryPro;
  src:
    local("ParryPro"),
    local("Parry Pro"),
    url("#{$cdn-url}/production/fonts/ParryPro-NormalItalic.woff") format("woff"),
    url("#{$cdn-url}/production/fonts/ParryPro-NormalItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

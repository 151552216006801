/**
 * ---------------------------------------------------------------------------------------------------- #
 *
 * Icon Font Mapping
 *
 */

.ico {
  display: inline-block;
  font-family: spoonflower-icons !important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-tiktok-circle::before {
  content: "\e9e9";
}

.ico-arrow-down1::before {
  content: "\e9e5";
}

.ico-arrow-left1::before {
  content: "\e9e8";
}

.ico-printer::before {
  content: "\e9e7";
}

.ico-calendar-2::before {
  content: "\e9e4";
}

.ico-act-colorfastness::before {
  content: "\e9d7";
}

.ico-act-crock::before {
  content: "\e9d8";
}

.ico-act-fire::before {
  content: "\e9d9";
}

.ico-act-high-traffic::before {
  content: "\e9da";
}

.ico-act-low-traffic::before {
  content: "\e9db";
}

.ico-act-physical::before {
  content: "\e9dc";
}

.ico-add::before {
  content: "\e9c1";
}

.ico-alarm::before {
  content: "\e904";
}

.ico-alarm-check::before {
  content: "\e901";
}

.ico-alarm-off::before {
  content: "\e902";
}

.ico-alarm-on::before {
  content: "\e903";
}

.ico-alarm-important::before {
  content: "\e9e3";
}

.ico-app::before {
  content: "\e908";
}

.ico-app-globe::before {
  content: "\e905";
}

.ico-app-lock::before {
  content: "\e906";
}

.ico-app-remove::before {
  content: "\e907";
}

.ico-arrow::before {
  content: "\e90f";
}

.ico-arrow-circle-left::before {
  content: "\e909";
}

.ico-arrow-circle-right::before {
  content: "\e90a";
}

.ico-arrow-down::before {
  content: "\e90e";
}

.ico-arrow-forward::before {
  content: "\e9cb";
}

.ico-arrow-left::before {
  content: "\e90c";
}

.ico-arrow-right::before {
  content: "\e90d";
}

.ico-arrow-up::before {
  content: "\e932";
}

.ico-attach::before {
  content: "\e910";
}

.ico-auction::before {
  content: "\e911";
}

.ico-book::before {
  content: "\e913";
}

.ico-book-bookmark::before {
  content: "\e912";
}

.ico-bookmark::before {
  content: "\e915";
}

.ico-bookmark-add::before {
  content: "\e914";
}

.ico-brightness::before {
  content: "\e916";
}

.ico-brush::before {
  content: "\e917";
}

.ico-bulb-off::before {
  content: "\e918";
}

.ico-bulb-on::before {
  content: "\e919";
}

.ico-bullhorn::before {
  content: "\e94e";
}

.ico-burst::before {
  content: "\e958";
}

.ico-calculator::before {
  content: "\e91a";
}

.ico-calendar::before {
  content: "\e91b";
}

.ico-cancel::before {
  content: "\e91c";
}

.ico-cart::before {
  content: "\e91d";
}

.ico-cart2::before {
  content: "\e95a";
}

.ico-case::before {
  content: "\e91e";
}

.ico-chart-area::before {
  content: "\e91f";
}

.ico-chart-bar::before {
  content: "\e923";
}

.ico-chart-bar-down::before {
  content: "\e920";
}

.ico-chart-bar-scale::before {
  content: "\e921";
}

.ico-chart-bar-up::before {
  content: "\e922";
}

.ico-chart-down::before {
  content: "\e924";
}

.ico-chart-line::before {
  content: "\e925";
}

.ico-chart-pie::before {
  content: "\e926";
}

.ico-chart-up::before {
  content: "\e927";
}

.ico-check::before {
  content: "\e9c6";
}

.ico-checkmark::before {
  content: "\e928";
}

.ico-chevron-down::before {
  content: "\e95d";
}

.ico-chevron-left::before {
  content: "\e968";
}

.ico-chevron-right::before {
  content: "\e993";
}

.ico-chevron-up::before {
  content: "\e996";
}

.ico-circle::before {
  content: "\e9dd";
}

.ico-clear-filter::before {
  content: "\e9ce";
}

.ico-clock::before {
  content: "\e929";
}

.ico-close::before {
  content: "\e9a2";
}

.ico-collection-add::before {
  content: "\e9c2";
}

.ico-collection-in::before {
  content: "\e9a3";
}

.ico-comment::before {
  content: "\e92e";
}

.ico-comment-check::before {
  content: "\e92a";
}

.ico-comment-delete::before {
  content: "\e92b";
}

.ico-comment-minus::before {
  content: "\e92c";
}

.ico-comment-plus::before {
  content: "\e92d";
}

.ico-console::before {
  content: "\e92f";
}

.ico-contrast::before {
  content: "\e930";
}

.ico-copy::before {
  content: "\e931";
}

.ico-cut::before {
  content: "\e933";
}

.ico-delete::before {
  content: "\e934";
}

.ico-disk::before {
  content: "\e935";
}

.ico-display-off::before {
  content: "\e936";
}

.ico-display-on::before {
  content: "\e937";
}

.ico-document::before {
  content: "\e939";
}

.ico-document-empty::before {
  content: "\e938";
}

.ico-edit::before {
  content: "\e9a4";
}

.ico-edit-pencil::before {
  content: "\e9e6";
}

.ico-facebook::before {
  content: "\e9a6";
}

.ico-facebook-circle::before {
  content: "\e9a5";
}

.ico-favorites-add::before {
  content: "\e93a";
}

.ico-favorites-remove::before {
  content: "\e93b";
}

.ico-film::before {
  content: "\e93c";
}

.ico-filter::before {
  content: "\e9cf";
}

.ico-flag::before {
  content: "\e93d";
}

.ico-flag1::before {
  content: "\e93e";
}

.ico-flag2::before {
  content: "\e9a7";
}

.ico-flash::before {
  content: "\e93f";
}

.ico-flickr::before {
  content: "\e9a9";
}

.ico-flickr-circle::before {
  content: "\e9a8";
}

.ico-folder::before {
  content: "\e942";
}

.ico-folder-bookmark::before {
  content: "\e940";
}

.ico-folder-in::before {
  content: "\e941";
}

.ico-font::before {
  content: "\e949";
}

.ico-font-case::before {
  content: "\e943";
}

.ico-font-down::before {
  content: "\e944";
}

.ico-font-italic::before {
  content: "\e945";
}

.ico-font-p::before {
  content: "\e946";
}

.ico-font-underline::before {
  content: "\e947";
}

.ico-font-up::before {
  content: "\e948";
}

.ico-frame::before {
  content: "\e94a";
}

.ico-gear::before {
  content: "\e94b";
}

.ico-globe::before {
  content: "\e94c";
}

.ico-hat::before {
  content: "\e94d";
}

.ico-heart::before {
  content: "\e9aa";
}

.ico-help::before {
  content: "\e94f";
}

.ico-help-chat::before {
  content: "\e9c0";
}

.ico-home::before {
  content: "\e950";
}

.ico-inbox::before {
  content: "\e951";
}

.ico-info::before {
  content: "\e952";
}

.ico-instagram-circle::before {
  content: "\e9ab";
}

.ico-key::before {
  content: "\e953";
}

.ico-keyboard::before {
  content: "\e954";
}

.ico-laptop::before {
  content: "\e955";
}

.ico-lightbulb-filled::before {
  content: "\e9e1";
}

.ico-lightbulb-outlined::before {
  content: "\e9e2";
}

.ico-link::before {
  content: "\e956";
}

.ico-lock::before {
  content: "\e957";
}

.ico-lock2::before {
  content: "\e9ac";
}

.ico-logo::before {
  content: "\e99c";
}

.ico-logo-paypal::before {
  content: "\e9cd";
}

.ico-logo-sepa::before {
  content: "\e9d0";
}

.ico-magnifier::before {
  content: "\e9ad";
}

.ico-mail::before {
  content: "\e9de";
}

.ico-mail1::before {
  content: "\e959";
}

.ico-med::before {
  content: "\e95b";
}

.ico-menu::before {
  content: "\e9ae";
}

.ico-mic::before {
  content: "\e95c";
}

.ico-minus::before {
  content: "\e9b0";
}

.ico-minus-square::before {
  content: "\e9af";
}

.ico-money::before {
  content: "\e95e";
}

.ico-moving::before {
  content: "\e95f";
}

.ico-note::before {
  content: "\e960";
}

.ico-on-off::before {
  content: "\e961";
}

.ico-outbox::before {
  content: "\e962";
}

.ico-paint::before {
  content: "\e963";
}

.ico-palette::before {
  content: "\e9b1";
}

.ico-paper-plane::before {
  content: "\e964";
}

.ico-pencil::before {
  content: "\e965";
}

.ico-phone::before {
  content: "\e966";
}

.ico-photo::before {
  content: "\e967";
}

.ico-pinterest-circle::before {
  content: "\e9bf";
}

.ico-pinterest2::before {
  content: "\e9b2";
}

.ico-plus::before {
  content: "\e9b4";
}

.ico-plus-square::before {
  content: "\e9b3";
}

.ico-reload::before {
  content: "\e969";
}

.ico-remove::before {
  content: "\e9c7";
}

.ico-resize::before {
  content: "\e96a";
}

.ico-roll::before {
  content: "\e9b5";
}

.ico-roostery::before {
  content: "\e9ca";
}

.ico-rss::before {
  content: "\e96b";
}

.ico-ruler::before {
  content: "\e96c";
}

.ico-ruler-horizontal::before {
  content: "\e9e0";
}

.ico-safe::before {
  content: "\e96d";
}

.ico-save::before {
  content: "\e96e";
}

.ico-search::before {
  content: "\e9b6";
}

.ico-share::before {
  content: "\e90b";
}

.ico-share-square::before {
  content: "\e900";
}

.ico-shopping-bag::before {
  content: "\e96f";
}

.ico-smile::before {
  content: "\e971";
}

.ico-smile-sad::before {
  content: "\e970";
}

.ico-sound::before {
  content: "\e972";
}

.ico-spanner::before {
  content: "\e973";
}

.ico-speaker::before {
  content: "\e974";
}

.ico-star::before {
  content: "\e975";
}

.ico-stop::before {
  content: "\e976";
}

.ico-storage::before {
  content: "\e977";
}

.ico-studio::before {
  content: "\e9b7";
}

.ico-sum::before {
  content: "\e978";
}

.ico-tag::before {
  content: "\e97c";
}

.ico-tag-add::before {
  content: "\e979";
}

.ico-tag-cancel::before {
  content: "\e97a";
}

.ico-tag-remove::before {
  content: "\e97b";
}

.ico-tags::before {
  content: "\e9b8";
}

.ico-trash::before {
  content: "\e9b9";
}

.ico-trash-mac::before {
  content: "\e97d";
}

.ico-trash2::before {
  content: "\e97e";
}

.ico-tray::before {
  content: "\e981";
}

.ico-tray-full::before {
  content: "\e97f";
}

.ico-tray-mail::before {
  content: "\e980";
}

.ico-twitter-circle::before {
  content: "\e9ba";
}

.ico-user::before {
  content: "\e9bb";
}

.ico-users::before {
  content: "\e9bc";
}

.ico-video::before {
  content: "\e98a";
}

.ico-video-forward::before {
  content: "\e982";
}

.ico-video-next::before {
  content: "\e983";
}

.ico-video-pause::before {
  content: "\e984";
}

.ico-video-play::before {
  content: "\e985";
}

.ico-video-previous::before {
  content: "\e986";
}

.ico-video-rec::before {
  content: "\e987";
}

.ico-video-rewind::before {
  content: "\e988";
}

.ico-video-stop::before {
  content: "\e989";
}

.ico-view-eye::before {
  content: "\e9cc";
}

.ico-view-eye-off::before {
  content: "\e9d1";
}

.ico-volume-down::before {
  content: "\e98b";
}

.ico-volume-up::before {
  content: "\e98c";
}

.ico-wallet::before {
  content: "\e98d";
}

.ico-warning::before {
  content: "\e98e";
}

.ico-warning1::before {
  content: "\e98f";
}

.ico-wifi::before {
  content: "\e990";
}

.ico-youtube-circle::before {
  content: "\e9bd";
}

.ico-zoom-in::before {
  content: "\e991";
}

.ico-zoom-out::before {
  content: "\e992";
}

.ico-video-camera::before {
  content: "\e9c3";
}

.ico-arrow-return::before {
  content: "\e9be";
}

.ico-help-outline::before {
  content: "\e99d";
}

.ico-help2::before {
  content: "\e99e";
}

.ico-menu-dots::before {
  content: "\e9a1";
}

.ico-test-swatch::before {
  content: "\e9c9";
}

.ico-star-full::before {
  content: "\e9c4";
}

.ico-star-empty::before {
  content: "\e9c5";
}

.ico-spinner::before {
  content: "\e994";
}

.ico-spinner1::before {
  content: "\e995";
}

.ico-facebook2::before {
  content: "\e997";
}

.ico-instagram::before {
  content: "\e998";
}

.ico-twitter::before {
  content: "\e999";
}

.ico-flickr3::before {
  content: "\e99a";
}

.ico-pinterest::before {
  content: "\e99b";
}

.ico-paypal::before {
  content: "\e99f";
}

.ico-youtube::before {
  content: "\ea9d";
}

.ico-truck::before {
  content: "\e9c8";
}

.ico-credit-card::before {
  content: "\e9a0";
}

// careful, this is different from the generated css from icomoon
// don't change! Remove css classes for e9d2 and e9d3 from the generated css
// update the content if necessary
// used in SearchHeader.js
.ico-swipe-background::before {
  content: "\e9d2";
}

.ico-swipe-arrows::before {
  content: "\e9d3";
}

.ico-remove-circle::before {
  content: "\e9d4";
}

.ico-add-circle::before {
  content: "\e9d5";
}

.ico-checkmark-circle::before {
  content: "\e9d6";
}

.ico-chat::before {
  content: "\e9df";
}

.ico-metallic-wallpaper::before {
  content: "\e9ea";
}

.ico-drawer-arrow-left::before {
  content: "\e9eb";
}

.ico-heart-outline::before {
  content: "\e9ec";
}

.ico-heart-full::before {
  content: "\e9ed";
}

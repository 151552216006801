/*
 * Swiper Slider
 */

.b-swiper {
  @include respond-to(large-desktop) {
    .swiper-pagination {
      display: none;
    }

    &.x-fabric-page-swiper {
      width: calc(100% - 80px); // thumbnail width
    }

    &.x-square-image-page-swiper {
      width: calc(100% - 70px); // thumbnail width
    }
  }

  @include respond-to(desktop) {
    .vendor-cta-link {
      background-color: rgba($color-black, .3);

      &:hover,
      &:focus {
        background-color: rgba($color-black, .5);
      }
    }

    &.x-shop-page-swiper {
      .carousel-image,
      .carousel-image .fallback-image {
        height: auto;
        max-width: 440px;
      }

      .image,
      .fallback-image {
        max-width: none;
      }
    }
  }

  @include respond-to(small-desktop-down) {
    width: 100%;
  }

  @include respond-to(desktop, tablet) {
    &.x-shop-page-swiper {
      .vendor-cta-link {
        max-width: 440px;
      }

      .image,
      .image .fallback-image,
      .carousel-image,
      .carousel-image .fallback-image {
        width: auto;
        height: 440px;
        max-height: 100%;
      }
    }

    .cta-text {
      font-size: $content-font-size-s;
      line-height: $content-line-height-xxl;
    }

    .btn-cta {
      padding: 8px 15px;
    }
  }

  @include respond-to(tablet-down) {
    .vendor-cta-link {
      background-color: rgba($color-black, .5);

      .btn-cta {
        width: auto;
      }
    }
  }

  @include respond-to(mobile) {
    &.x-shop-page-swiper {
      .image,
      .carousel-image {
        width: auto;
        max-height: 440px;
      }

      .vendor-cta-link {
        width: auto;
      }
    }

    .cta-text {
      font-size: $content-font-size-xs;
      line-height: $content-line-height-xxl;
      white-space: normal;
    }

    .btn-cta {
      padding: $single-space;
    }
  }

  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: $pentuple-space;
  vertical-align: top;

  &.x-shop-page-swiper {
    width: 100%;
  }

  &.x-square-image-page-swiper {
    .carousel-image .fallback-image {
      width: 100%;
      height: 100%;
    }

    .fallback-image-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;
      max-width: 650px;
      max-height: 650px;
      position: relative;
    }
  }

  .fallback-image-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }

  .image {
    .fallback-image-wrapper {
      // This was added to override library (react-image-magnify) settings,
      // to ensure, that the image re-sizes correctly when switching fabric size,
      // window size or fabric type
      width: auto !important;
    }
  }

  .carousel-image,
  .carousel-image .fallback-image {
    max-width: 100%;
    text-align: center;
    vertical-align: top;
  }

  // lazy loading
  .carousel-image .swiper-lazy {
    @include loading;

    overflow: hidden;
    display: inline-block;
    color: transparent;

    &::before {
      @include center;
    }
  }

  .small-carousel-image,
  .large-carousel-image {
    // This was added to override library (react-image-magnify) settings,
    // to ensure, that the image re-sizes correctly when switching fabric size,
    // window size or fabric type
    width: auto !important;
    max-width: none;
  }

  .vendor-cta-link {
    box-sizing: border-box;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    padding: $single-space;

    .cta-text {
      display: inline-block;
      font-weight: $content-font-weight-light;
      color: $color-white;
    }

    .btn-cta {
      box-sizing: border-box;
      display: inline-block;
      height: 38px;
      border-radius: $border-radius-curved;
      font-weight: $content-font-weight-bold;
      color: $color-black;
      background-color: $color-white;
    }

    &.home-good-link {
      justify-content: flex-end;
    }
  }

  .button-prev,
  .button-next {
    position: absolute;
    z-index: $z-level-1;
    top: 46%;
    right: $single-space;
    width: $quadruple-space;
    height: $quadruple-space;
    margin-top: -20px;
    border-radius: 50%;
    background-color: $color-lighter-grey;

    &.swiper-button-disabled {
      display: none;
    }

    .ico {
      font-size: $content-font-size-xxs;
      line-height: $content-line-height-xxs;
      text-align: center;
    }
  }

  .button-prev {
    right: auto;
    left: $single-space;

    &.x-on-top-of-slides {
      left: $single-space;
    }
  }

  .button-next .x-on-top-of-slides {
    right: $single-space;
  }

  .swiper-pagination.swiper-pagination-bullets {
    bottom: 20px;
    min-height: 22px;

    .swiper-pagination-bullet-active {
      background-color: $color-black;
    }

    .swiper-pagination-bullet {
      width: 7px;
      height: 7px;
      margin: 0 4px;
    }
  }

  .carousel-link-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: $pentuple-space;
    background-color: $color-gray-500;
    text-align: left;
    display: flex;
    align-items: center;

    .carousel-link {
      font-size: $content-font-size-l;
      margin-left: $double-space;
      color: white;
      padding: $half-space, $single-space;
      font-family: $font-primary;
      font-weight: $content-font-weight-normal;
      padding-bottom: 2px;
      border-bottom: 1px solid white;
    }
  }
}
